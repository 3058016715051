<template>
    <div class="sort-filters-panel">
        <ButtonFAB
            v-if="isMobile"
            :second-digit="selectedFiltersCount"
            :variant="TWO_ACTIONS_VARIANT"
            class="button-fab"
            @default-button-click="openSortModal()"
            @second-button-click="openFiltersModal()"
        >
            <template #label>{{ sortLabel }}</template>

            <template #icon>
                <Icon :icon="SortIcon" width="16px" height="16px" />
            </template>

            <template #second-label>{{ $t('Filter') }}</template>

            <template #second-icon>
                <Icon :icon="Filter" width="16px" height="16px" />
            </template>
        </ButtonFAB>

        <div v-if="!isMobile" class="sort-filters-wrapper">
            <SortDropdown class="sort" @apply-sort="applySearchConfig()" />

            <div class="divider" />

            <div class="filters-buttons">
                <DropdownFilterComponent
                    v-for="filter in handledFilters"
                    :key="filter.code"
                    :data-test-id="filter.code"
                    :label="filter.label"
                    :digit="filter.selectedCount"
                    :is-selected="filter.selectedCount > 0"
                    @click="openModalWithInitialFilter(filter)"
                />
            </div>

            <ButtonComponent
                v-if="showAllFiltersButton"
                :variant="BUTTON_COMPONENT_VARIANT"
                class="all-filters-button"
                @click="openFiltersModal()"
            >
                <template #icon>
                    <Icon :icon="Filter" />
                </template>
                {{ $t('More filters') }}
            </ButtonComponent>
        </div>

        <Sort
            v-if="isMobile"
            :is-open="isSortModalOpen"
            :lock-body-scroll="true"
            @close="closeSortModal()"
            @apply-sort="applySearchConfig()"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    SPECIAL_ACTION_CODE,
    PRICE_FILTER_CODE,
    HOT_DEAL_ACTION_CODE,
    OCCASION_FILTER_CODE,
} from '@types/Filters';

import { interactionResponse } from '@assets/performance';
import { applySearchConfiguration } from '@assets/filters';

import { FILTERS_MODAL, SORT_MODAL } from '@search/configs/modals';

import SortDropdown from '@molecules/SortDropdown/SortDropdown';
import DropdownFilterComponent from '@search-molecules/DropdownFilterComponent/DropdownFilterComponent';

import { Filter, Sort as SortIcon } from '@eobuwie-ui/icons/v2/navigation';

import {
    ButtonComponent,
    BUTTON_COMPONENT_VARIANTS,
} from '@eobuwie-ui/components/ButtonComponent/v1';

import {
    ButtonFAB,
    BUTTON_FAB_VARIANTS,
} from '@eobuwie-ui/components/ButtonFAB/v1';

import { Icon } from '@eobuwie-ui/components/Icon/v1';

const EXCLUDED_FILTERS_CODES = [
    SPECIAL_ACTION_CODE,
    HOT_DEAL_ACTION_CODE,
    OCCASION_FILTER_CODE,
];

const {
    mapGetters: mapSortGetters,
    mapState: mapSortState,
} = createNamespacedHelpers('sort');

export default {
    name: 'SortFiltersPanel',

    components: {
        SortDropdown,
        ButtonComponent,
        ButtonFAB,
        Icon,
        DropdownFilterComponent,
        Sort: () => ({
            component: import(
                /* webpackChunkName: "sort-modal" */
                '@molecules/Sort/Sort'
            ),
        }),
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        ...mapSortState(['orderOptions', 'selectedSortOption']),
        ...mapSortGetters(['isSortChanged', 'isSortSelected']),

        selectedFiltersCount() {
            return this.$filterManager.selectedFiltersCount || 0;
        },

        filters() {
            return this.$filterManager.filters;
        },

        handledFilters() {
            return this.filters
                .filter(
                    ({ code, options }) =>
                        !EXCLUDED_FILTERS_CODES.includes(code) &&
                        (!options || options?.length > 0)
                )
                .filter(
                    (__, index) =>
                        index < this.INITIALLY_DISPLAYED_FILTERS_COUNT
                );
        },

        showAllFiltersButton() {
            return this.filters.length > this.INITIALLY_DISPLAYED_FILTERS_COUNT;
        },

        sortLabel() {
            const currentOption =
                this.orderOptions.find(
                    ({ code, order }) =>
                        code === this.selectedSortOption.code &&
                        order === this.selectedSortOption.order
                )?.label || this.$t('Sort');

            return currentOption;
        },

        isSortModalOpen() {
            return this.$modals.isOpen(SORT_MODAL);
        },
    },

    watch: {
        isMobile(toValue, fromValue) {
            const changedFromMobileToDesktop = !!(fromValue && !toValue);

            if (changedFromMobileToDesktop) {
                this.closeFiltersModal();
                this.closeSortModal();
            }
        },
    },

    beforeCreate() {
        this.BUTTON_COMPONENT_VARIANT =
            BUTTON_COMPONENT_VARIANTS.PRIMARY_ACCENT;
        this.INITIALLY_DISPLAYED_FILTERS_COUNT = 7;
        this.TWO_ACTIONS_VARIANT = BUTTON_FAB_VARIANTS.TWO_ACTIONS;
        this.Filter = Filter;
        this.SortIcon = SortIcon;
    },

    methods: {
        closeFiltersModal() {
            this.$modals.close(FILTERS_MODAL);
        },

        closeSortModal() {
            this.$modals.close(SORT_MODAL);
        },

        openFiltersModal() {
            this.$modals.open(FILTERS_MODAL);
        },

        async openModalWithInitialFilter(filter) {
            if (filter.code === PRICE_FILTER_CODE) {
                await this.$modals.open(FILTERS_MODAL);
            } else {
                await this.$modals.open(FILTERS_MODAL, { filter: filter.code });
            }
        },

        openSortModal() {
            this.$modals.open(SORT_MODAL);
        },

        async applySearchConfig() {
            this.closeFiltersModal();
            this.closeSortModal();

            await interactionResponse();

            applySearchConfiguration(this);
        },
    },
};
</script>

<style lang="scss" scoped>
.sort-filters-panel {
    @apply border-none;

    .sort-filters-wrapper {
        @apply hidden;
    }

    .divider {
        @apply w-[1px] h-ui-9 bg-ui-swap-gray200;
    }

    .button-fab {
        @apply fixed bottom-ui-0 left-1/2 w-full px-ui-6 py-ui-4;
        @apply justify-center z-2;
        transform: translateX(-50%);

        // move filters above smart banner when it's visible
        @at-root .smart-banner-visible & {
            @apply bottom-[66px];
        }
    }

    @screen lg {
        @apply flex justify-between items-center py-ui-3;
        @apply bg-ui-container-default-default z-3;
        @apply sticky top-[120px];

        .sort-filters-wrapper {
            @apply flex gap-ui-4 flex-nowrap items-center w-full;
            @apply whitespace-nowrap;
        }

        .filters-buttons {
            @apply flex flex-wrap gap-x-ui-2 h-ui-11;
            @apply overflow-hidden;
        }
    }
}
</style>
